'use client';

import React from 'react';
import { EventDispatcherProvider as OlaIsaacEventDispatcherProvider } from '@olaisaac/event-dispatcher-sdk';
type Props = {
  options: {
    mixpanelDebugMode?: boolean;
    mixpanelProjectToken?: string;
    isMixpanelEnabled?: boolean;
  };
};
export const EventDispatcherSDKProvider = ({
  options,
  children
}: React.PropsWithChildren<Props>) => {
  const {
    mixpanelDebugMode,
    mixpanelProjectToken,
    isMixpanelEnabled
  } = options;
  return <OlaIsaacEventDispatcherProvider options={{
    mixpanelDebugMode,
    mixpanelProjectToken
  }} isMixpanelEnabled={isMixpanelEnabled} data-sentry-element="OlaIsaacEventDispatcherProvider" data-sentry-component="EventDispatcherSDKProvider" data-sentry-source-file="sdkProvider.tsx">
      {children}
    </OlaIsaacEventDispatcherProvider>;
};